@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
  }
.link_hover_border_bottom:hover{
    @apply border-b-2;
}

.link_hover_border_bottom_transition_border{
    display: inline-block;
    text-decoration: none;
}
.link_hover_border_bottom_transition_border:after {
    content: '';
    display: block;
    width: 0;
    height: 0px;
    margin-top: -6px;
    @apply bg-green;
    transition: width .3s;
}
.link_hover_border_bottom_transition_border:hover::after {
    width: 100%;
}

.is-fadeout{
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s ease-in 300ms, opacity 300ms;
}
.is-open{
    visibility: visible;
    opacity: 1;
    transition: visibility 0s ease-out 0s, opacity 300ms;
}

/* utils */

.text-underline-bold{
    display: inline-block !important;
}
.text-underline-bold::after{
    content: '';
    display: block !important;
    margin-top: -10px;
    height: 12px;
    @apply bg-green;
}

.form_input_helpdesk{
    @apply border-t-0 border-b-2 border-green px-2 py-2 rounded focus:outline-none focus:bg-gray-500 transition-all duration-300 text-gray-800 text-lg tracking-wide;
}
.contact__form_control{
    @apply my-4 flex flex-col;
}

/* Responsive (opt)*/

/* Small */
@media all and (max-width: 480px){ 
   
}
  
@media all and (min-width: 480px) and (max-width: 768px) { }
@media all and (min-width: 768px) and (max-width: 1024px) { }
@media all and (min-width: 1024px) and (max-width: 1280px) { }
 
 